import React from "react";
import { Link, graphql } from "gatsby";
import TableOfContents from "../components/TableOfContents";
import { get } from "lodash";

const WordChangeLog = (props) => (
  <TableOfContents
    name="Word LX™ Download Archive"
    entries={get(props, "data.allContentfulDownloadArchiveFile.nodes")}
    target="version"
  >
    <p>
      For release notes, please visit the{" "}
      <Link to="/wordlx-changelog">Word LX™ Changelog</Link>.
    </p>
  </TableOfContents>
);

export const query = graphql`
  {
    allContentfulDownloadArchiveFile(sort: { order: DESC, fields: version }) {
      nodes {
        date
        version
        url
      }
    }
  }
`;

export default WordChangeLog;
